<template lang='pug'>
  extends ../templates/PageBase.pug
  block content
    .container-fluid.container-fixed-lg
      .row
        .col-lg-6
          .card.card-default.card-input
            .card-header
              .card-title
                | Text
            .card-body
              h5
                | Paste texts with links here
              form(role='form')
                .form-group.required
                  .irs-wrapper
                    code-editor(mode='text' ref='linkEditor' :controls='[]' height='701px')

        .col-lg-6
          .card.card-default.output
            .card-header
              .card-title Links &ndash;&nbsp;
                small
                  strong.text-success  Unique: {{ founds }}.
                  strong.text-primary  Total: {{ sumLinks }}.
                  strong.text-danger  Duplicates: {{ dups }}
            .card-body
              h5 Press CMD (Mac) / STRG (Win) and click Links to open in Background
              .checkbox.check-primary
                ol
                  li(v-for='(link, cnt) in output')
                    a.m-r-10(@click='gotoLine(link.line)' href='javascript:;')
                      i.fa.fa-arrow-circle-left
                    input(type='checkbox' :id='`id_link_${cnt}`')
                    label.unique-link(:for='`id_link_${cnt}`' @click='openLink(link.url)')
                      //i.fa.fa-instagram.m-r-10
                      | {{ link.url }}

    .row
      .col-lg-12
        .card.card-transparent
          .card-header
            .card-title
              | About
          .card-body
            .row
              .col-lg-4
                .card.card-default(data-pages='card')
                  .card-header
                    .card-title
                      | Find out what this tool is.
                  .card-body
                    h4 How can this tool help you to save up to 70% of your time?
                    p
                      | Instagram Engagement-Groups is a very powerfull tool to grow fast.
                      | Unfortunately liking and commenting can be very time consuming and annoying.
                      | Especially when you are in a lot of engagement groups.
                      | Most of other users post the same link in different groups.
                      strong This tool removes duplicate links for you, so do just have to visit a post once.

                    h4
                      span.semi-bold Instructions
                    ol
                      li
                        | Use a browser so that you can copy text of you engagement groups.
                        | We recommend Chrome, FireFox or Safari. InternetExplorer might not be supported.
                      li Copy & paste all texts from engagement group in the text section.
                      li Open one link after another by left-clicking at the Links Section.
                      li Like or comment the post.
                      li
                        | close the window by press (ALT-F4 on Windows) or (CMD+W on Mac).
                        | Keep your lefthand on the keyboard, this saves you some seconds for each post.
                    p Repeat from step 3 until all posts were visited. If you are done, you can close this site or leave a comment.

                    h4 Never get
                      span.semi-bold  banned
                      |  from engagement groups again
                    p
                      | You forgot to like a group because you lost the track?
                      | With the checklist, you'll always see which links you have visited or not.

                    h4 This tool is
                      span.semi-bold  NOT
                    ul
                      li
                        | not a bot: it doesn't like or comment automatically for you.
                      li
                        | doesn't save your links. If you close the window, your data is lost.
                        | We'll ask you before you close the window if this was intended.
                      li
                        | guarantee that you not getting penalty from instagram when using this too extensively.
                        | We are using this tool on our own.
                        | We are trying to avoid instagram Machine-Learning-Algorithm to detect us as spammy.
                        | This is why you should open just one link at the time than close the window.
                    h4 This tool is
                    ul
                      li
                        strong Free:&nbsp;
                        | please support us by commenting or sharing this, if it helps you. If it doesn't help you, let us know what we can improve.
                      li very helpfull when using the way it is designed for and saves you a lot of time.
                      li finding all links in a conversation and list them, so you can see which one is visited and not.
                      li make to handle several ten thousands of lines. So don't worry about pasting lots of texts.
                      li
                        | Showing you the exact location of a broken link, so you can message the person.
                        | click on this symbol&nbsp;
                        i.fa.fa-arrow-circle-left
                        | &nbsp;to jump to the position of the link in the engagement group text.
              .col-lg-4
                .card.card-default(data-pages='card')
                  .card-header
                    .card-title
                      | Subscribe for news and updates
                  .card-body
                    h4 Hey 👋, we hope you find this tool
                      span.semi-bold &nbsp;helpfull.
                    p
                      | We've created this tool to support us, we hope it helps you the same way it helps us.
                      | We'll add more and more usefull tools to make your work more efficient and less annoying.
                    p
                      | Subscribe to get notified about the latest tools, updates and workflow.
                    include ../templates/mailChimpSubscribe.pug

              .col-lg-4
                .card.card-default(data-pages='card')
                  .card-header
                    .card-title
                      | Comment Section
                  .card-body
                    p
                      | Tell us and other if this tools helps you.
                      | Or just send us improvements what can make your live easier.
                    div#disqus_thread
</template>

<script>
  import CodeEditor from '../components/CodeEditor'
  export default {
    name: 'LinkUniqueifier',
    components: {CodeEditor},
    mounted() {
      const self = this

      if (process.env.NODE_ENV !== 'development') {
        this.setupDisqus()
      }

      window.onbeforeunload = function (e) {
        e = e || window.event
        // For IE and Firefox prior to version 4
        if (e) { e.returnValue = 'Sure?' }
        // For Safari
        return 'Sure?'
      }

      this.$on('editorContentPaste', function (event) {
        self.$parent.$emit('pgNotification', {message: 'Paste successful', type: 'success'})

        this.$ga.event({eventCategory: 'editor', eventAction: 'paste', eventLabel: 'link-uniquifier'})
      })

      this.$on('editorContentChanged', function (event) {
        self.input = event.editor.getValue()
      })
    },
    data() {
      return {
        output: [],
        input: '',
        founds: 0,
        sumLinks: 0,
        dups: 0
      }
    },
    watch: {
      input(newVal) {
        let found = new Set()
        let unique = []
        let sumLinks = 0
        newVal.split('\n').forEach(function (l, lineNum) {
          const urls = l.match(/(https?:\/\/[^\s/$.?#].[^\s]*)/gi) || []

          urls.forEach(function (url) {
            if (!found.has(url)) {
              found.add(url)
              unique.push({url: url, line: lineNum})
            }
            sumLinks += 1
          })
        })

        this.output = unique.sort((a, b) => {
          if (a.url < b.url) return -1
          else if (a.url > b.url) return 1
          return 0
        })

        this.founds = unique ? unique.length : 0
        this.sumLinks = sumLinks
        this.dups =  unique ? (sumLinks - unique.length) : 0
      }
    },
    methods: {
      setupDisqus() {
        const self = this

        if (document.querySelectorAll('script[data-timestamp]').length === 0) {
          (function () { // DON'T EDIT BELOW THIS LINE
            const d = document, s = d.createElement('script')
            s.src = 'https://annoyingdev-1.disqus.com/embed.js'
            s.setAttribute('data-timestamp', + new Date());
            (d.head || d.body).appendChild(s)
          })()
        } else {
          window.DISQUS.reset({
            reload: true,
            config: function () {
              this.page.identifier = this.$route.params.course
              this.page.url = `https://annoyingdev.com${self.$route.path}`
            }
          })
        }
      },
      gotoLine(line) {
        this.$refs.linkEditor.editor.gotoLine(line + 1, 0)
        this.$ga.event({eventCategory: 'editor', eventAction: 'download', eventLabel: 'note'})
      },
      openLink(link) {
        window.open(link)
        this.$ga.event({eventCategory: 'link', eventAction: 'open', eventLabel: link})
      }
    }
  }
</script>

<style scoped lang="scss">
  label.unique-link {
    font-family: Menlo, Monaco, 'Andale Mono', 'Lucida Console', 'Courier New', monospace;
    white-space: nowrap;
  }

  .card {
    &.output .card-body {
      min-height: 775px;
      max-height: 775px;
      overflow: scroll;
    }

    .checkbox > ol > li:hover {
      background-color: #daeffd !important;
    }
  }
</style>
